<template>
  <div>
    <def-bg-pattern theme="theme-dark">
      <def-container-full-width :use-slot="true">
        <div>
          <h1 class="loginTitle">Login</h1>
        </div>
      </def-container-full-width>
%OPENISAMTEMPLATETAG%
<div id="dosdnotificationmessage" isamtemplatestyle="<% if (!templateContext.macros['@DOSD_NOTIFICATION_MESSAGE@']) { templateContext.response.body.write('display: none;'); } %>">
%CLOSEISAMTEMPLATETAG%
<def-alert
          text="@DOSD_NOTIFICATION_MESSAGE@"
          icon=""
          icon-position="right"
          type="alert-default"
          :items="items"
          :show-close-btn="false"
        ></def-alert>
</div>
    </def-bg-pattern>
    <def-bg-pattern-grey theme="theme-light" class="fitScreen">
      <div class="formContainer">
        <hide-at breakpoint="mediumAndAbove">
          <def-container-full-width :use-slot="true">
            <def-login-form></def-login-form>

          </def-container-full-width>
        </hide-at>
        <show-at breakpoint="mediumAndAbove">
          <def-container-seventy-thirty
            class="seventyThirtyContainer"
            :reverse="false"
            :align-middle="false"
          >
            <def-column-seventy>
              <def-msg-box
                msgType="error"
                icon="fas fa-times"
              ></def-msg-box>
              <def-login-form></def-login-form>
            </def-column-seventy>
            <def-column-thirty :indent="false">
              <def-qs title="Questions?">
                <p v-for="item in questions" :key="item">{{ item }}</p>
                <def-button-secondary
                  label="Read FAQ"
                  link="http://defence.gov.au/"
                  target="_blank"
                  :submit="false"
                  :margin="true"
                ></def-button-secondary>
              </def-qs>
            </def-column-thirty>
          </def-container-seventy-thirty>
        </show-at>
      </div>
    </def-bg-pattern-grey>
  </div>
</template>
<style>
.loginTitle {
  color: white !important;
  padding: 0.5rem 0;
}
.formContainer {
  padding-top: 2rem;
}
.seventyThirtyContainer,
.row > * {
  padding-bottom: 2rem;
}

.fitScreen {
  min-height: 100vh;
}

@media only screen and (min-width: 744px) and (max-width: 1128px) {
  /* Medium */
  .fitScreen {
    min-height: 90vh;
  }
}
@media only screen and (min-width: 1129px) {
  /* Large */
  .fitScreen {
    min-height: 55vh;
  }
}
</style>
<script>
import { showAt, hideAt } from "vue-breakpoints";
import DefLoginForm from "../components/DefLoginForm/DefLoginForm.vue";
import DefQs from "../components/DefQs/DefQs.vue";
import DefMsgBox from "../components/DefMsgBox/DefMsgBox.vue";

export default {
  data() {
    const msg =
      "Welcome to the Defence Online Services Domain (DOSD), an online gateway to access Defence applications.";
    const questions = [
      `For activated Home Portal users, login details have been sent via email from the application owner who has granted access to DOSD.`,
      `For first time Home Portal access to PSS, or for other support queries, refer to FAQs for more information.`,
    ];

    var errStr = "";
    return {
      msg,
      questions,
      errStr,
    };
  },
  components: { hideAt, showAt, DefLoginForm, DefQs, DefMsgBox, },
};
</script>
