<template>

  <div class="form-group dod-input-text">
    <label v-bind:for=id>{{ label }} <span v-if="checkRequired()" class="required">*</span>
      <div v-if="helptext != null" class="text-tooltip"><i class="fa-solid fa-circle-question"></i>
        <span v-html="helptext" class="form-text text-muted text-tooltiptext">
          <i class="fa-solid fa-circle-question"></i> {{ helptext }}
        </span>
      </div>
    </label>
    <div v-if="inlineLabel != null" style="white-space:nowrap; display: inline">{{ inlineLabel }}
    <input v-bind:id=id v-bind:name=name v-bind:placeholder=placeholder v-bind:type=type v-bind:value=value class="form-control-inline" :required=required :disabled=disabled v-bind:maxlength=maxlength v-bind:autocomplete=autocomplete />
    </div>
    <input v-else v-bind:id=id v-bind:name=name v-bind:placeholder=placeholder v-bind:type=type v-bind:value=value class="form-control" :required=required :disabled=disabled v-bind:maxlength=maxlength v-bind:autocomplete=autocomplete />
  </div>

</template>

<style>

.required, .text-tooltip .fa-circle-question {
  color:#cf4520;
}
.text-tooltip .fa-circle-question
{
  padding-right:1rem;
}

.text-tooltip {
  position: relative;
  display: inline-block;
}

.text-tooltip .text-tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #FFFFFF;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  box-shadow: 0px 0px 5px 2px #5a6770;
  padding: 1rem;
  display: flex;

  /* Position the tooltip */
  position: absolute;
  top: -1rem;
  left: -1rem;
  z-index: 1;
}

.text-tooltip:hover .text-tooltiptext {
  visibility: visible;
}

.app-cell{
  border-top: 1px solid #6C757D;
}
.app-cell a {
  color: #000000;
}
.app-container{
  display: flex;
  padding: 15px 0;
}
.app-arrow{
  float:right;
  margin-left: auto;
  align-items: center;
  display: flex;
}
.app-icon{
  padding: 0 15px;
}
.app-arrow, .app-subtitle{
  color: #6C757D;
}
.pageTitle {
  color: white !important;
  padding: 0.5rem 0;
}
.card {
  padding: 1rem;
  margin: 1rem 0;
}
.help-text{
  position:absolute;
  background-color: #FFFFFF;
  padding:2rem;
}

@media only screen and (min-width: 744px) and (max-width: 1128px) {
  /* Medium */
  .card {
    padding: 1.5rem 2rem;
  }
}
@media only screen and (min-width: 1129px) {
  /* Large */
  .card {
    padding: 3.5rem 4rem;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.noLeftPadding {
  padding-left: 0;
}
.paddingTop {
  padding-top: 2rem;
}
.mb0 {
  margin-bottom: 0;
}
</style>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "DefSelectFixed",
  props: {
    /**
    * The component label text
    */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
    * The name for the component used to group checkboxes
    */
    name: {
      type: String,
      required: false,
      default: null,
    },
    /**
    * Determines the input type, valid options are text, email, search, password, number or date
    */
    type: {
      type: String,
      required: false,
      default: "txt",
    },
    /**
    * The value of the input
    */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
    * Require field - This needs to be unique
    */
    id: {
      type: String,
      required: true,
      default: null,
    },
    /**
    * Sets the first option, this option will be disabled
    */
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    /**
    * 	Specifies the form the component belongs to
    */
    form: {
      type: String,
      required: false,
      default: null,
    },
    /*
    * This will display a label next to the input text box
    */
    inlineLabel: {
    type: String,
    required: false,
    default: null,
    },
    /**
    * This will display help text below the component
    */
    helptext: {
      type: String,
      required: false,
      default: null,
    },
    /**
    * Font Awesome html ref i.e. fas fa-image, fad fa-video etc
    */
    icon: {
      type: String,
      required: false,
      default: null,
    },
    /**
    * 	Where to position the icon. Options left or right
    */
    iconposition: {
      type: String,
      required: false,
      default: "left",
    },
    /**
    * If set to true component is disabled
    */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
    * If set to true component is required
    */
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
    * If set to true the input will display a green border with a green tick
    */
    valid: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
    * If set to true the input will display a red border with a red cross
    */
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
    * If set to true the input will display as a search input with icon
    */
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
    * If set to true component is set to readonly
    */
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
   /**
   * Sets the maxLength attribute
   */
   maxlength: {
     type: String,
     required: false,
   },
  autocomplete: {
    type: String,
    required: false,
  },
  },
  methods:{
    checkRequired(){
      return this.required == true && this.label.length > 0
    },
  },
});
</script>
