<template>
  <div :class=msgType isamtemplatestyle="<% if (!templateContext.macros['@USER_ERROR_MESSAGE@']) { templateContext.response.body.write('display: none;'); } %>" id="error-msg" name="error-msg">
    <div class="icon">
      <b><i :class="icon"></i></b>
      <div class="msg">
      @USER_ERROR_MESSAGE@</div>
    </div>
  </div>
</template>

<style>
.error {
  margin-bottom: 25px;
}
.error .msg {
  background-color: #FAE5E7;
  width:90%;
  color: #000000;
  float: right;
  padding: 10px 0px;
  padding-left:15px;
  text-align: left;
}
.error .icon {
  background-color: #D0021B;
  color:#FFFFFF;
  text-align: center;
  height: 100%;
  display: block;
  overflow: auto;
  border-radius:10px;
  position: relative;
}
.icon > b {
 margin: 0;
  position: absolute;
  top: 25%;
  left: 25px;
}
</style>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "DefMsgBox",
  props: {
    /**
    * Message to display
    */
    message: {
      type: String,
      default: "@USER_ERROR_MESSAGE@",
    },
    /**
    * Message Type, used to change Styling
    */
    msgType: {
      type: String,
      required: false,
      default: "error",
    },
    /**
    * Left Side Icon. use the classes found in https://fontawesome.com/
    */
    icon: {
      type: String,
      required: false,
      default: "fas fa-times",
    },
  },
});
</script>
