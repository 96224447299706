<template>
  <div class="card">
    <h4>{{ title }}</h4>
    <slot></slot>
  </div>
</template>
<style>
@media only screen and (max-device-width: 744px) {
  /* Small */
  .card {
    padding: 1rem;
  }
}
@media only screen and (max-device-width: 1128px) and (min-device-width: 744px) {
  /* Medium */
  .card {
    padding: 1.5rem 2rem;
  }
}
@media only screen and (min-device-width: 1128px) {
  /* Large */
  .card {
    padding: 3.5rem 2.5rem;
  }
}
.card > * {
  margin-bottom: 1.5em;
}
</style>
<script>
export default {
  name: "DefQs",
  props: {
    title: {
      type: String,
      required: true,
      default: "Question",
    },
  },
};
</script>
