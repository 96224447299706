<template>
  <div>
    <div class="card">
      <p>{{ msg }}</p>
      <def-form action="@ACTION@" method="post" novalidate>
        <input type="hidden" id="operation" name="operation" value="verify">
        <def-input-text-fixed
          label="Username"
          name="username"
          type="text"
          value=""
          id="username"
          placeholder=""
          icon=""
          icon-position="left"
          :disabled="false"
          :required="true"
          :readonly="false"
          :valid="false"
          :in-valid="false"
          :search="false"
          maxlength="100"
        ></def-input-text-fixed>
        <def-input-text-fixed
          label="Password"
          name="password"
          type="password"
          value=""
          id="password"
          placeholder=""
          form=""
          icon=""
          icon-position="left"
          :disabled="false"
          :required="true"
          :readonly="false"
          :valid="false"
          :in-valid="false"
          :search="false"
          maxlength="100"
        ></def-input-text-fixed>
        <hide-at breakpoint="mediumAndAbove">
          <def-button-primary
            label="Login"
            target="_self"
            :submit="true"
            :margin="false"
          ></def-button-primary>
        </hide-at>
        <show-at breakpoint="mediumAndAbove">
          <div class="flex">
            <def-button-primary
              label="Login"
              target="_self"
              :submit="true"
              :margin="false"
            ></def-button-primary>
            <def-content :use-slot="true">
              <p class="mb0">
                Forgot <a href="/username-recovery/provide-email">username</a> or
                <a href="/password-reset/request">password</a>?
              </p>
            </def-content>
          </div>
        </show-at>
        <hide-at breakpoint="mediumAndAbove">
          <div class="paddingTop">
            <def-content :use-slot="true" class="noLeftPadding">
              <p>
                Forgot <a href="/username-recovery/provide-email">username</a> or
                <a href="/password-reset/request">password</a>?
              </p>
            </def-content>
          </div>
        </hide-at>
      </def-form>
    </div>
  </div>
</template>
<style>
/* Small */
.card {
  padding: 1rem;
  margin: 1rem 0;
}

@media only screen and (min-width: 744px) and (max-width: 1128px) {
  /* Medium */
  .card {
    padding: 1.5rem 2rem;
  }
}
@media only screen and (min-width: 1129px) {
  /* Large */
  .card {
    padding: 3.5rem 4rem;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.noLeftPadding {
  padding-left: 0;
}
.paddingTop {
  padding-top: 2rem;
}
.mb0 {
  margin-bottom: 0;
}
</style>

<script lang="ts">

import Vue from "vue";
import { showAt, hideAt } from "vue-breakpoints";
import DefInputTextFixed from '../../components/DefInputTextFixed/DefInputTextFixed.vue';

export default Vue.extend({
  name: "DefLoginForm",
  components: { hideAt, showAt, DefInputTextFixed },
  data() {
    var msg =
      "Welcome to the Defence Online Services Domain (DOSD), an online gateway to access Defence applications.";
    
    return {
      msg
    };
  },
});
</script>
